<template>
  <loader />
  <router-view :key="$route.path"></router-view>
  <catalog-filter :is-open="$store.state.isPopupOpen" />
  <my-svg />
</template>

<style>
@import '@/assets/shop-design/css/style.css';
</style>

<script>
import { mapActions } from 'vuex';
import { defineAsyncComponent } from 'vue';

const CatalogFilter = defineAsyncComponent({
  loader: () => import('@/views/components/Modals/CatalogFilter.vue')
});

export default {
  components: {
    CatalogFilter
  },
  methods: {
    ...mapActions([
      'GET_RAZDELS_LIST',
      'SYNC_LOCAL_DATA',
    ]),
    telegramInit() {
      Telegram.WebApp.ready();
      if (typeof Telegram.WebApp.initDataUnsafe.user !== 'undefined') {
        this.$store.state.$app_mode = 1;
        Telegram.WebApp.expand();
        Telegram.WebApp.enableClosingConfirmation();
        Telegram.WebApp.MainButton.setParams({
          text: 'ОФОРМИТЬ ЗАКАЗ',
        });
      } else {
        this.$store.state.$app_mode = 2;
      }

    },
    telegramAuthState() {
      if (this.$store.state.$app_mode === 1) {
        this.$store.commit('setUserId', '');
        this.$auth(Telegram.WebApp.initDataUnsafe.user);
      } else {
        //авторизация через браузер
      }
    },

  },
  mounted() {
    this.GET_RAZDELS_LIST();
    this.telegramInit();
    this.telegramAuthState();
  },
  updated() {
    if (this.$store.state.$app_mode === 1) {
      let curPage = this.$route.name;
      let cart = this.$store.state.cart;
      if (curPage === 'cart' && cart.length > 0) {
        Telegram.WebApp.MainButton.show();
      } else {
        Telegram.WebApp.MainButton.hide();
      }
    }
  }
}
</script>
