//$app_mode
//1 - через Telegram
//2 - через браузер
const state = {
  $app_mode: 1,
  cart: [],
  wishlist: [],
  isLoading: true,
  userId: "",
  auth_key: "",
  isShowTGMainBtn: false,
  razdelsList: [],
  currentRazdelId: 0,
  defaultRazdelId: 6,
  isPopupOpen: false,
  choosen_filters: {},
};

export default state