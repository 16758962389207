<template>
    <transition name="fade">
        <div class="app-loader" v-if="loading">
            <img src="@/assets/img/logo.webp" style="max-width: 140px;"/>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'loader',
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        setTimeout(() => {
            this.loading = false;
        }, 1500);
    }
    /*
    computed: {
        isLoading() {
            return this.$store.state.isLoading;
        }
    }
    */
}
</script>
<style scoped>
@import '@/assets/css/loader.css';
</style>