import axios from "axios";

/**
 * авторизация / регистрация пользователя
 * –после открытия приложения
 * –после авторизации через Telegram Widget
 * @param {*} userData 
 */
export async function authTWA(userData) {

    let res = {};

    if (userData) {
        try {
            const response = await axios.post('api/clients', {
                params: userData,
                action: 'auth_user',
                prefix: 'obj',
                headers: {
                    "Access-Control-Allow-Origin": true
                }
            });
            if (response.data.success) {
                console.log('api-auth', response.data.data.tg_username);
                this.$store.commit('setUserId', response.data.data.tg_username);
                res = response.data.data.user_info;
                //синхронизация корзины и избранного
                //this.SYNC_LOCAL_DATA();
            }
        } catch (e) {
            console.log('authErr', e);
        }
    }

    return res;
}