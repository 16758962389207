import { createStore } from 'vuex';
//используется чтобы сохранять данные Vuex между перезагрузкой страниц
//сохраняет данные в localStorage
import createPersistedState from "vuex-persistedstate";

//данные хранилища
import state from '@/store/state';
//получаем данные из хранилища
import getters from '@/store/getters';
//записываем / изменяем данные в хранилище
import mutations from '@/store/mutations';
//действия через которых мы иницилизируем мутации
import actions from '@/store/actions';



export default createStore({
  state,
  getters,
  mutations,
  actions,
  plugins: [createPersistedState()]
});
