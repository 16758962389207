<template>
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" hidden>
        <symbol width="16" height="9" viewBox="0 0 16 9" fill="none" id="arrow-down">
            <path
                d="M0.916536 2.00005C0.916078 1.7664 0.997959 1.53996 1.14797 1.36005C1.23244 1.25879 1.33619 1.17509 1.45326 1.11374C1.57033 1.05239 1.69843 1.01459 1.83022 1.00251C1.96202 0.990438 2.09491 1.00432 2.2213 1.04336C2.34768 1.08241 2.46508 1.14585 2.56676 1.23005L7.96016 5.71005L13.3636 1.39005C13.4666 1.30699 13.585 1.24495 13.7121 1.20752C13.8392 1.17009 13.9726 1.158 14.1044 1.17194C14.2362 1.18587 14.364 1.22557 14.4804 1.28875C14.5967 1.35192 14.6994 1.43732 14.7824 1.54005C14.874 1.64352 14.9431 1.76468 14.9854 1.89595C15.0276 2.02721 15.0421 2.16573 15.0279 2.30283C15.0138 2.43992 14.9712 2.57262 14.903 2.6926C14.8348 2.81259 14.7423 2.91726 14.6315 3.00005L8.59409 7.83005C8.41404 7.97713 8.1882 8.05754 7.95513 8.05754C7.72206 8.05754 7.49622 7.97713 7.31617 7.83005L1.27878 2.83005C1.15701 2.72973 1.06075 2.6023 0.99781 2.45809C0.934872 2.31388 0.907027 2.15696 0.916536 2.00005Z"
                fill="#94A3B8" />
        </symbol>
        <symbol width="12" height="6" viewBox="0 0 12 6" fill="none" id="angle-up">
            <path d="M0.999998 1.5L6 5.25L11 1.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </symbol>
        <symbol width="16" height="16" viewBox="0 0 16 16" fill="none" id="filter-icon">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.69002 3.84996C7.19342 3.09341 7.0669 2.0823 6.3926 1.47312C5.71829 0.863941 4.69957 0.840401 3.99785 1.41779C3.29614 1.99517 3.12304 2.99935 3.59096 3.77835C4.05887 4.55735 5.02668 4.87624 5.86602 4.52796C6.20145 4.38801 6.48809 4.15215 6.69002 3.84996Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.69002 14.165C7.19342 13.4084 7.0669 12.3973 6.3926 11.7881C5.71829 11.1789 4.69957 11.1554 3.99785 11.7328C3.29614 12.3102 3.12304 13.3144 3.59096 14.0934C4.05887 14.8724 5.02668 15.1912 5.86602 14.843C6.20145 14.703 6.48809 14.4672 6.69002 14.165V14.165Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.30998 9.00695C8.80658 8.25039 8.93311 7.23929 9.60741 6.6301C10.2817 6.02092 11.3004 5.99738 12.0021 6.57477C12.7039 7.15215 12.877 8.15634 12.409 8.93534C11.9411 9.71433 10.9733 10.0332 10.134 9.68495C9.79856 9.54499 9.51191 9.30914 9.30998 9.00695V9.00695Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M7 2.07599C6.58579 2.07599 6.25 2.41178 6.25 2.82599C6.25 3.2402 6.58579 3.57599 7 3.57599V2.07599ZM15 3.57599C15.4142 3.57599 15.75 3.2402 15.75 2.82599C15.75 2.41178 15.4142 2.07599 15 2.07599V3.57599ZM3.327 3.57599C3.74121 3.57599 4.077 3.2402 4.077 2.82599C4.077 2.41178 3.74121 2.07599 3.327 2.07599V3.57599ZM1 2.07599C0.585786 2.07599 0.25 2.41178 0.25 2.82599C0.25 3.2402 0.585786 3.57599 1 3.57599V2.07599ZM7 12.392C6.58579 12.392 6.25 12.7278 6.25 13.142C6.25 13.5562 6.58579 13.892 7 13.892V12.392ZM15 13.892C15.4142 13.892 15.75 13.5562 15.75 13.142C15.75 12.7278 15.4142 12.392 15 12.392V13.892ZM3.327 13.892C3.74121 13.892 4.077 13.5562 4.077 13.142C4.077 12.7278 3.74121 12.392 3.327 12.392V13.892ZM1 12.392C0.585786 12.392 0.25 12.7278 0.25 13.142C0.25 13.5562 0.585786 13.892 1 13.892V12.392ZM9 8.73399C9.41421 8.73399 9.75 8.3982 9.75 7.98399C9.75 7.56978 9.41421 7.23399 9 7.23399V8.73399ZM1 7.23399C0.585786 7.23399 0.25 7.56978 0.25 7.98399C0.25 8.3982 0.585786 8.73399 1 8.73399V7.23399ZM12.673 7.23399C12.2588 7.23399 11.923 7.56978 11.923 7.98399C11.923 8.3982 12.2588 8.73399 12.673 8.73399V7.23399ZM15 8.73399C15.4142 8.73399 15.75 8.3982 15.75 7.98399C15.75 7.56978 15.4142 7.23399 15 7.23399V8.73399ZM7 3.57599H15V2.07599H7V3.57599ZM3.327 2.07599H1V3.57599H3.327V2.07599ZM7 13.892H15V12.392H7V13.892ZM3.327 12.392H1V13.892H3.327V12.392ZM9 7.23399H1V8.73399H9V7.23399ZM12.673 8.73399H15V7.23399H12.673V8.73399Z"
                fill="currentColor" />
        </symbol>
        <symbol width="16" height="16" viewBox="0 0 16 16" fill="none" id="search-icon">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1 6.76551C1.00003 4.01511 2.94296 1.64777 5.64053 1.1113C8.3381 0.57483 11.039 2.01866 12.0913 4.55977C13.1437 7.10088 12.2544 10.0315 9.96738 11.5593C7.68036 13.0871 4.63257 12.7866 2.688 10.8415C1.60716 9.76039 0.999984 8.29425 1 6.76551Z"
                stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.529 12.5295L15 15.0005" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </symbol>
        <symbol width="16" height="15" viewBox="0 0 16 15" fill="none" id="fav-icon">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.9997 13.9911L5.0767 10.9911L2.1767 7.99109C0.607767 6.33308 0.607767 3.73809 2.1767 2.08009C2.94494 1.34175 3.98548 0.955914 5.04937 1.01489C6.11326 1.07387 7.10478 1.57236 7.7867 2.39109L7.9997 2.60009L8.2107 2.38209C8.89262 1.56336 9.88414 1.06487 10.948 1.00589C12.0119 0.946914 13.0525 1.33275 13.8207 2.07109C15.3896 3.72909 15.3896 6.32408 13.8207 7.98209L10.9207 10.9821L7.9997 13.9911Z"
                stroke="#64748B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </symbol>
        <symbol width="16" height="15" viewBox="0 0 16 15" fill="none" id="fav-filled-icon">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M7.9997 13.9911L5.0767 10.9911L2.1767 7.99109C0.607767 6.33308 0.607767 3.73809 2.1767 2.08009C2.94494 1.34175 3.98548 0.955914 5.04937 1.01489C6.11326 1.07387 7.10477 1.57236 7.7867 2.39109L7.9997 2.60009L8.2107 2.38209C8.89262 1.56336 9.88414 1.06487 10.948 1.00589C12.0119 0.946914 13.0525 1.33275 13.8207 2.07109C15.3896 3.72909 15.3896 6.32408 13.8207 7.98209L10.9207 10.9821L7.9997 13.9911Z"
                fill="#EB5757" stroke="#EB5757" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </symbol>
        <symbol width="7" height="12" viewBox="0 0 7 12" fill="none" id="angle-right">
            <path d="M1 11L6 6L1 1" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </symbol>
        <symbol width="12" height="12" viewBox="0 0 12 12" fill="none" id="close-white">
            <path
                d="M0.46967 10.4697C0.176777 10.7626 0.176777 11.2374 0.46967 11.5303C0.762563 11.8232 1.23744 11.8232 1.53033 11.5303L0.46967 10.4697ZM6.53033 6.53033C6.82322 6.23744 6.82322 5.76256 6.53033 5.46967C6.23744 5.17678 5.76256 5.17678 5.46967 5.46967L6.53033 6.53033ZM5.46967 5.46967C5.17678 5.76256 5.17678 6.23744 5.46967 6.53033C5.76256 6.82322 6.23744 6.82322 6.53033 6.53033L5.46967 5.46967ZM11.5303 1.53033C11.8232 1.23744 11.8232 0.762563 11.5303 0.46967C11.2374 0.176777 10.7626 0.176777 10.4697 0.46967L11.5303 1.53033ZM6.53033 5.46967C6.23744 5.17678 5.76256 5.17678 5.46967 5.46967C5.17678 5.76256 5.17678 6.23744 5.46967 6.53033L6.53033 5.46967ZM10.4697 11.5303C10.7626 11.8232 11.2374 11.8232 11.5303 11.5303C11.8232 11.2374 11.8232 10.7626 11.5303 10.4697L10.4697 11.5303ZM5.46967 6.53033C5.76256 6.82322 6.23744 6.82322 6.53033 6.53033C6.82322 6.23744 6.82322 5.76256 6.53033 5.46967L5.46967 6.53033ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM1.53033 11.5303L6.53033 6.53033L5.46967 5.46967L0.46967 10.4697L1.53033 11.5303ZM6.53033 6.53033L11.5303 1.53033L10.4697 0.46967L5.46967 5.46967L6.53033 6.53033ZM5.46967 6.53033L10.4697 11.5303L11.5303 10.4697L6.53033 5.46967L5.46967 6.53033ZM6.53033 5.46967L1.53033 0.46967L0.46967 1.53033L5.46967 6.53033L6.53033 5.46967Z"
                fill="#94A3B8" />
        </symbol>
        <symbol width="16" height="10" viewBox="0 0 16 10" fill="none" id="share-white">
            <path
                d="M11.3989 0.46922C11.1057 0.176575 10.6309 0.176978 10.3382 0.470119C10.0456 0.76326 10.046 1.23813 10.3391 1.53078L11.3989 0.46922ZM14.4701 5.65478C14.7633 5.94742 15.2381 5.94702 15.5308 5.65388C15.8234 5.36074 15.823 4.88587 15.5299 4.59322L14.4701 5.65478ZM15.5132 5.67094C15.8152 5.38752 15.8304 4.91289 15.5469 4.61082C15.2635 4.30875 14.7889 4.29364 14.4868 4.57706L15.5132 5.67094ZM10.3558 8.45306C10.0538 8.73648 10.0386 9.21111 10.3221 9.51318C10.6055 9.81525 11.0801 9.83036 11.3822 9.54694L10.3558 8.45306ZM15 5.874C15.4142 5.874 15.75 5.53821 15.75 5.124C15.75 4.70979 15.4142 4.374 15 4.374L15 5.874ZM4.63 5.124L4.62772 5.874L4.63 5.874L4.63 5.124ZM2.0674 6.17655L1.53861 5.64468L1.53861 5.64468L2.0674 6.17655ZM0.250003 8.73093C0.24886 9.14514 0.583718 9.48185 0.99793 9.483C1.41214 9.48414 1.74885 9.14928 1.75 8.73507L0.250003 8.73093ZM10.3391 1.53078L14.4701 5.65478L15.5299 4.59322L11.3989 0.46922L10.3391 1.53078ZM14.4868 4.57706L10.3558 8.45306L11.3822 9.54694L15.5132 5.67094L14.4868 4.57706ZM15 4.374L4.63 4.374L4.63 5.874L15 5.874L15 4.374ZM4.63228 4.374C3.47334 4.37048 2.36048 4.82757 1.53861 5.64468L2.59619 6.70842C3.13589 6.17184 3.86667 5.87168 4.62772 5.874L4.63228 4.374ZM1.53861 5.64468C0.716739 6.4618 0.253201 7.57199 0.250003 8.73093L1.75 8.73507C1.7521 7.97402 2.05649 7.24499 2.59619 6.70842L1.53861 5.64468Z"
                fill="#94A3B8" />
        </symbol>
        <symbol width="12" height="12" viewBox="0 0 12 12" fill="none" id="close-red">
            <path
                d="M0.46967 10.4697C0.176777 10.7626 0.176777 11.2374 0.46967 11.5303C0.762563 11.8232 1.23744 11.8232 1.53033 11.5303L0.46967 10.4697ZM6.53033 6.53033C6.82322 6.23744 6.82322 5.76256 6.53033 5.46967C6.23744 5.17678 5.76256 5.17678 5.46967 5.46967L6.53033 6.53033ZM5.46967 5.46967C5.17678 5.76256 5.17678 6.23744 5.46967 6.53033C5.76256 6.82322 6.23744 6.82322 6.53033 6.53033L5.46967 5.46967ZM11.5303 1.53033C11.8232 1.23744 11.8232 0.762563 11.5303 0.46967C11.2374 0.176777 10.7626 0.176777 10.4697 0.46967L11.5303 1.53033ZM6.53033 5.46967C6.23744 5.17678 5.76256 5.17678 5.46967 5.46967C5.17678 5.76256 5.17678 6.23744 5.46967 6.53033L6.53033 5.46967ZM10.4697 11.5303C10.7626 11.8232 11.2374 11.8232 11.5303 11.5303C11.8232 11.2374 11.8232 10.7626 11.5303 10.4697L10.4697 11.5303ZM5.46967 6.53033C5.76256 6.82322 6.23744 6.82322 6.53033 6.53033C6.82322 6.23744 6.82322 5.76256 6.53033 5.46967L5.46967 6.53033ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM1.53033 11.5303L6.53033 6.53033L5.46967 5.46967L0.46967 10.4697L1.53033 11.5303ZM6.53033 6.53033L11.5303 1.53033L10.4697 0.46967L5.46967 5.46967L6.53033 6.53033ZM5.46967 6.53033L10.4697 11.5303L11.5303 10.4697L6.53033 5.46967L5.46967 6.53033ZM6.53033 5.46967L1.53033 0.46967L0.46967 1.53033L5.46967 6.53033L6.53033 5.46967Z"
                fill="#EF4444" />
        </symbol>
        <symbol width="16" height="10" viewBox="0 0 16 10" fill="none" id="share-grey">
            <path
                d="M11.3989 0.46922C11.1057 0.176575 10.6309 0.176978 10.3382 0.470119C10.0456 0.76326 10.046 1.23813 10.3391 1.53078L11.3989 0.46922ZM14.4701 5.65478C14.7633 5.94742 15.2381 5.94702 15.5308 5.65388C15.8234 5.36074 15.823 4.88587 15.5299 4.59322L14.4701 5.65478ZM15.5132 5.67094C15.8152 5.38752 15.8304 4.91289 15.5469 4.61082C15.2635 4.30875 14.7889 4.29364 14.4868 4.57706L15.5132 5.67094ZM10.3558 8.45306C10.0538 8.73648 10.0386 9.21111 10.3221 9.51318C10.6055 9.81525 11.0801 9.83036 11.3822 9.54694L10.3558 8.45306ZM15 5.874C15.4142 5.874 15.75 5.53821 15.75 5.124C15.75 4.70979 15.4142 4.374 15 4.374L15 5.874ZM4.63 5.124L4.62772 5.874L4.63 5.874L4.63 5.124ZM2.0674 6.17655L1.53861 5.64468L1.53861 5.64468L2.0674 6.17655ZM0.250003 8.73093C0.24886 9.14514 0.583718 9.48185 0.99793 9.483C1.41214 9.48414 1.74885 9.14928 1.75 8.73507L0.250003 8.73093ZM10.3391 1.53078L14.4701 5.65478L15.5299 4.59322L11.3989 0.46922L10.3391 1.53078ZM14.4868 4.57706L10.3558 8.45306L11.3822 9.54694L15.5132 5.67094L14.4868 4.57706ZM15 4.374L4.63 4.374L4.63 5.874L15 5.874L15 4.374ZM4.63228 4.374C3.47334 4.37048 2.36048 4.82757 1.53861 5.64468L2.59619 6.70842C3.13589 6.17184 3.86667 5.87168 4.62772 5.874L4.63228 4.374ZM1.53861 5.64468C0.716739 6.4618 0.253201 7.57199 0.250003 8.73093L1.75 8.73507C1.7521 7.97402 2.05649 7.24499 2.59619 6.70842L1.53861 5.64468Z"
                fill="#64748B" />
        </symbol>
        <symbol width="32" height="34" viewBox="0 0 32 34" fill="none" id="cart-added">
            <rect width="32" height="34" rx="4" fill="#10B981" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.00035 14.3C8.987 13.3199 9.77024 12.5143 10.7504 12.5H21.2503C22.2305 12.5143 23.0137 13.3199 23.0004 14.3V22.4C23.0276 24.3605 21.4608 25.972 19.5004 26H12.5004C10.5399 25.972 8.9731 24.3605 9.00035 22.4V14.3Z"
                stroke="#E8EDFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M19.5004 15.2V11.6C19.5276 9.63953 17.9608 8.02797 16.0004 8C14.0399 8.02797 12.4731 9.63953 12.5004 11.6V15.2"
                stroke="#E8EDFB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 20.2222L14.8462 22L19 18" stroke="#F8FAFC" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </symbol>
        <symbol width="102" height="102" viewBox="0 0 102 102" fill="none" id="success-icon">
            <circle cx="51" cy="51" r="49" stroke="#6FCF97" stroke-width="4" />
            <circle cx="51" cy="51" r="38" fill="#27AE60" />
            <path d="M35 50L46 61L67.5 39.5" stroke="#F8FAFC" stroke-width="4" stroke-linecap="round"
                stroke-linejoin="round" />
        </symbol>
        <symbol width="20" height="16" viewBox="0 0 20 16" fill="none" id="exit-icon">
            <path
                d="M4.54401 5.53165C4.83763 5.23949 4.83881 4.76462 4.54665 4.47099C4.25449 4.17737 3.77962 4.17619 3.48599 4.46835L4.54401 5.53165ZM0.470994 7.46835C0.177372 7.76051 0.176188 8.23538 0.468349 8.52901C0.760511 8.82263 1.23538 8.82381 1.52901 8.53165L0.470994 7.46835ZM1.52901 7.46835C1.23538 7.17619 0.760511 7.17737 0.468349 7.47099C0.176188 7.76462 0.177372 8.23949 0.470994 8.53165L1.52901 7.46835ZM3.48599 11.5317C3.77962 11.8238 4.25449 11.8226 4.54665 11.529C4.83881 11.2354 4.83763 10.7605 4.54401 10.4683L3.48599 11.5317ZM1 7.25C0.585787 7.25 0.25 7.58579 0.25 8C0.25 8.41421 0.585787 8.75 1 8.75V7.25ZM15 8.75C15.4142 8.75 15.75 8.41421 15.75 8C15.75 7.58579 15.4142 7.25 15 7.25V8.75ZM3.48599 4.46835L0.470994 7.46835L1.52901 8.53165L4.54401 5.53165L3.48599 4.46835ZM0.470994 8.53165L3.48599 11.5317L4.54401 10.4683L1.52901 7.46835L0.470994 8.53165ZM1 8.75L15 8.75V7.25L1 7.25V8.75Z"
                fill="#EB5757" />
            <path
                d="M7 11C7 13.2091 8.79086 15 11 15H15C17.2091 15 19 13.2091 19 11V5C19 2.79086 17.2091 1 15 1H11C8.79086 1 7 2.79086 7 5"
                stroke="#EB5757" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </symbol>
        <symbol width="102" height="102" viewBox="0 0 102 102" fill="none" id="not_found">
            <circle cx="51" cy="51" r="49" stroke="#CBD5E1" stroke-width="4" />
            <circle cx="51" cy="51" r="38" fill="#CBD5E1" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M51.0001 62.0837C57.1212 62.0837 62.0834 57.1215 62.0834 51.0003C62.0834 44.8792 57.1212 39.917 51.0001 39.917C44.8789 39.917 39.9167 44.8792 39.9167 51.0003C39.9167 57.1215 44.8789 62.0837 51.0001 62.0837Z"
                stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path
                d="M47 47.834C47 47.4198 46.6642 47.084 46.25 47.084C45.8358 47.084 45.5 47.4198 45.5 47.834H47ZM45.5 49.4173C45.5 49.8315 45.8358 50.1673 46.25 50.1673C46.6642 50.1673 47 49.8315 47 49.4173H45.5ZM56.5 47.834C56.5 47.4198 56.1642 47.084 55.75 47.084C55.3358 47.084 55 47.4198 55 47.834H56.5ZM55 49.4173C55 49.8315 55.3358 50.1673 55.75 50.1673C56.1642 50.1673 56.5 49.8315 56.5 49.4173H55ZM55.7706 54.917C56.1846 54.9057 56.5111 54.5608 56.4997 54.1467C56.4884 53.7327 56.1435 53.4062 55.7294 53.4176L55.7706 54.917ZM51.7917 54.959L51.992 55.6817C52.0153 55.6753 52.0384 55.6677 52.061 55.6589L51.7917 54.959ZM47.2899 56.8171C47.0044 57.1173 47.0163 57.592 47.3165 57.8774C47.6166 58.1629 48.0913 58.151 48.3768 57.8508L47.2899 56.8171ZM45.5 47.834V49.4173H47V47.834H45.5ZM55 47.834V49.4173H56.5V47.834H55ZM55.7294 53.4176C54.2894 53.4571 52.8667 53.7417 51.5223 54.259L52.061 55.6589C53.2464 55.2028 54.5009 54.9519 55.7706 54.917L55.7294 53.4176ZM51.5914 54.2362C49.9502 54.6911 48.4635 55.5831 47.2899 56.8171L48.3768 57.8508C49.3632 56.8137 50.6127 56.064 51.992 55.6817L51.5914 54.2362Z"
                fill="#475569" />
            <path d="M73 48.5V56" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M70 45L67 52.5H75.5" stroke="#475569" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            <path d="M33 48.5V56" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M30 45L27 52.5H35.5" stroke="#475569" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </symbol>
        <symbol width="60" height="18" viewBox="0 0 60 18" fill="none" id="sdek-logo">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.40135 13.3773H6.81411C2.77802 13.3773 5.71024 4.28224 9.10814 4.28224H13.196C13.8514 4.28224 15.0071 4.41036 15.5763 2.69014L16.4559 0.0183411H10.8502C7.81454 0.0183411 5.45149 1.15294 3.74393 3.05615C0.794466 6.31354 -0.205933 11.4009 0.501248 13.8349C1.17393 16.0857 3.00225 17.5681 5.86545 17.6047L8.0905 17.6229H10.833L11.5057 15.4819C12.0058 13.963 10.8847 13.3773 9.40135 13.3773ZM40.8968 9.60754L41.7418 6.69786H32.721C31.2205 6.69786 30.5478 7.13706 30.2891 8.05203L29.4439 10.9617H38.4647C39.9653 10.9617 40.638 10.5225 40.8968 9.60754ZM28.0985 14.7316L27.2533 17.6412H36.2742C37.7576 17.6412 38.4475 17.202 38.7062 16.2871L39.5514 13.3773H30.5305C29.0472 13.3773 28.3745 13.8165 28.0985 14.7316ZM43.001 2.94635L43.8464 0.0366408H34.8253C33.3248 0.0366408 32.6521 0.47584 32.3934 1.39084L31.5482 4.30055H40.569C42.0526 4.30055 42.725 3.86135 43.001 2.94635ZM29.3749 3.53195C28.9954 0.823543 27.6328 0.0366408 24.3211 0.0366408H18.2843L14.7656 10.9617H16.9906C18.3187 10.9617 18.9914 10.9801 19.6986 8.96704L21.1647 4.28224H23.407C25.3215 4.28224 24.8903 6.82596 23.9244 9.29644C23.062 11.4741 21.5614 13.3957 19.7503 13.3957H16.0075C14.5069 13.3957 13.8169 13.8349 13.5409 14.7499L12.6095 17.6595H15.352L18.0428 17.6412C20.423 17.6229 22.3721 17.44 24.6488 15.2623C27.0636 12.9382 29.8578 6.97234 29.3749 3.53195ZM59.6975 0.0183411H54.4713L49.5555 5.56324C48.9861 6.20373 48.3997 6.84423 47.8304 7.59456H47.7786L50.2279 0.0183411H45.9679L40.2241 17.6412H44.4844L46.3126 12.0964L48.2098 10.3944L49.7106 15.4087C50.1762 16.9641 50.6595 17.6412 51.6944 17.6412H54.9542L51.6079 7.79586L59.6975 0.0183411Z"
                fill="#00B33C" />
        </symbol>
        <symbol width="16" height="19" viewBox="0 0 16 19" fill="none" id="pin-icon">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.9502 3.06211C15.6838 5.81864 15.6838 10.2636 12.9502 13.0201L8.00018 18.0001L3.05018 13.0211C0.316606 10.2646 0.316606 5.81964 3.05018 3.06311C4.35902 1.74291 6.14095 1.00019 7.99997 1C9.85899 0.999812 11.6411 1.74218 12.9502 3.06211Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.8002 7.61111C10.7821 9.15308 9.52053 10.3901 7.9785 10.378C6.43647 10.3659 5.19447 9.1093 5.20046 7.56724C5.20645 6.02517 6.45817 4.77821 8.00025 4.77811C9.55565 4.78747 10.8091 6.0557 10.8002 7.61111Z"
                stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </symbol>
        <symbol width="24" height="69" viewBox="0 0 24 69" fill="none" id="agency-logo">
            <path d="M8.22817e-07 0L0 69L24 69L24 2.86197e-07L8.22817e-07 0Z" fill="#233947" />
            <mask id="mask0_427_1013" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="5" y="8" width="19"
                height="53">
                <path
                    d="M5.37231 60.9852L14.6299 60.9852L23.88 60.9852L23.88 8.01476L14.6299 8.01476L5.37232 8.01476L5.37231 60.9852Z"
                    fill="white" />
            </mask>
            <g mask="url(#mask0_427_1013)">
                <path
                    d="M13 22.8685C9.88189 22.8685 7.35913 25.3913 7.35913 28.5094C7.35913 31.6275 9.88189 34.1502 13 34.1502L13.0223 34.1502C14.7265 34.1502 16.3116 33.2721 17.212 31.8284L17.212 31.7986C18.1199 33.2572 19.7125 34.1502 21.4241 34.1502L28.903 34.1502C32.0211 34.1502 34.5439 31.6275 34.5439 28.5094C34.5439 25.3913 32.0211 22.8685 28.903 22.8685L21.4017 22.8685C19.6976 22.8685 18.1125 23.7466 17.212 25.1903L17.212 25.2201C16.3041 23.7615 14.7116 22.8685 13 22.8685ZM10.4103 28.5094C10.4103 27.207 11.4521 26.1429 12.7321 26.1429C13.64 26.1429 14.466 26.6712 14.853 27.5047L16.5646 30.6972L14.7414 29.5884L14.719 29.6331C14.3097 30.3921 13.5283 30.861 12.6874 30.8758C11.4223 30.8535 10.4103 29.8042 10.4103 28.5168L10.4103 28.5094ZM29.156 26.1429C30.436 26.1429 31.4779 27.1996 31.4779 28.5094C31.4779 29.8191 30.436 30.8758 29.156 30.8758L21.6845 30.8758C20.7766 30.8758 19.9506 30.3475 19.5562 29.514L19.0799 28.5094L19.6901 27.3857C20.1069 26.6191 20.8957 26.1429 21.7589 26.1429L29.156 26.1429Z"
                    fill="white" />
                <path
                    d="M26.1866 38.7195C26.1866 37.4454 25.1538 36.4125 23.8797 36.4125C22.6056 36.4125 21.5728 37.4454 21.5728 38.7195C21.5728 39.9936 22.6056 41.0264 23.8797 41.0264C25.1538 41.0264 26.1866 39.9936 26.1866 38.7195Z"
                    fill="white" />
                <path
                    d="M18.4175 8.70688C14.7412 8.70688 12.4492 12.6882 14.2947 15.8658L14.7115 16.61L7.76831 16.61L7.76831 19.8844L34.1642 19.8844L34.1642 16.61L18.3579 16.61L17.1747 14.5784C16.5124 13.4324 17.3384 11.9961 18.663 11.9961L34.1642 11.9961L34.1642 8.72176L29.8555 8.72176L29.8555 8.71432L18.4175 8.71432L18.4175 8.70688Z"
                    fill="white" />
                <path
                    d="M11.973 58.2318C11.973 56.9577 10.9402 55.9248 9.66608 55.9248C8.39199 55.9248 7.35913 56.9577 7.35913 58.2318C7.35913 59.5059 8.39199 60.5387 9.66608 60.5387C10.9402 60.5387 11.973 59.5059 11.973 58.2318Z"
                    fill="white" />
                <path d="M14.1013 56.5946L14.1013 59.869L34.1643 59.869L34.1643 56.5946L14.1013 56.5946Z"
                    fill="white" />
                <path
                    d="M31.5596 48.1929C31.5596 49.4431 30.5475 50.4552 29.2973 50.4552L17.1077 50.4552L17.1077 44.1967L14.1012 44.1967L14.1012 50.4477L7.76831 50.4477L7.76831 53.7221L29.0666 53.7221C32.0954 53.7221 34.5586 51.2589 34.5586 48.2301C34.5586 45.3873 32.1698 43.6162 32.1698 43.6162L30.2052 45.8859C30.2052 45.8859 31.5671 47.0692 31.5671 48.1854L31.5596 48.1929Z"
                    fill="white" />
            </g>
        </symbol>
        <symbol viewBox="0 0 576 512" id="warning-icon">
            <path
                d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z" />
        </symbol>
    </svg>
</template>

<script>
export default {
    name: 'my-svg'
}
</script>

<style scoped>
[hidden] {
    display: none;
}
</style>